import request from '@/utils/request'

export function getSocialOpportunityById(data) {
  return request({
    url: `/api/SocialOpportunities/GetSocialOpportunityById/${data}`,
    method: 'get',
  })
}

export function getSocialOpportunities() {
  return request({
    url: '/api/SocialOpportunities/GetSocialOpportunity',
    method: 'get',
  })
}

export function addSocialOpportunity(data) {
  return request({
    url: '/api/SocialOpportunities/AddSocialOpportunity',
    method: 'post',
    data,
  })
}

export function updateSocialOpportunity(data) {
  return request({
    url: '/api/SocialOpportunities/UpdateSocialOpportunity',
    method: 'put',
    data,
  })
}
export function deleteSocialOpportunity(data) {
  return request({
    url: `/api/SocialOpportunities/DeleteSocialOpportunity?socialOpportunityId=${data}`,
    method: 'delete',
  })
}
